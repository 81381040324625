import { render, staticRenderFns } from "./FalconIdo4.vue?vue&type=template&id=19564a46&scoped=true"
import script from "./FalconIdo4.vue?vue&type=script&lang=js"
export * from "./FalconIdo4.vue?vue&type=script&lang=js"
import style0 from "./FalconIdo4.vue?vue&type=style&index=0&id=19564a46&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.29_css-loader@6.11.0_webpack@5.92.1__lodash@4.17.21__tsmhhees436r6x4lpdmtm64kei/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19564a46",
  null
  
)

export default component.exports